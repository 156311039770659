<script setup>
const props = defineProps({
  label: {
    type: String,
  },
  max: {
    type: Number,
    default: 100,
  },
  min: {
    type: Number,
    default: 0,
  },
  step: {
    type: Number,
    required: false,
    default: 1,
  },
  range: {
    type: Boolean,
    required: false,
  },
  strict: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Array,
    default: (p) => [p.min, p.max],
  },
})

const emit = defineEmits(['input', 'change', 'update:modelValue'])

const value = ref([props.min, props.max])

const reRenderKey = ref(0)

watch(
  () => props.modelValue,
  (newValue) => {
    value.value = newValue
  },
  {immediate: true},
)

function handleInput(index, event) {
  let val = parseInt(event.target.value)

  if (!props.overlap) {
    if (index === 0 && val >= value.value[1]) {
      return event.preventDefault()
    }

    if (index === 1 && val <= value.value[0]) {
      return event.preventDefault()
    }
  }

  value.value[index] = val
  emit('update:modelValue', value.value)
  emit('input', val, value.value)
}

function handleChange(event) {
  const val = parseInt(event.target.value)
  emit('change', val, value.value)
  reRenderKey.value += 1
}
</script>

<style scoped>
@import 'assets/css/components/global/nu-range.css';
</style>

<template>
  <div
    :class="{'range-slider': range === 'range'}"
    class="nu-range"
    :key="reRenderKey"
  >
    <label
      class="nu-range-label"
      v-if="label"
      >{{ label }}</label
    >
    <div class="nu-range-inputs">
      <input
        :max="max"
        :min="min"
        :step="step"
        :value="value[0]"
        class="custom-input custom-input-from"
        name="from"
        type="range"
        @change="handleChange"
        @input="handleInput(0, $event)"
      />
      <input
        :max="max"
        :min="min"
        :step="step"
        :value="value[1]"
        class="custom-input custom-input-to"
        name="to"
        type="range"
        @change="handleChange"
        @input="handleInput(1, $event)"
      />
      <span class="nu-range-background"></span>
    </div>
    <div class="nu-range-values">
      <span>{{ value[0] }}</span>
      <span>{{ value[1] }}</span>
    </div>
  </div>
</template>
